<template>
    <div id="wrapper-password-chge" :key="langKey" v-if="isVisible" >
        <div class="cont-password cont-password-chge">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{text.title}}</p>
                <fa :icon="['fas', 'times']" @click="close" />
            </div>
            <div class="text-password">
                <label for="search" id="search-label">{{text.search}} :</label>
                <input type="text" name="search" id="search" :placeholder="text.placeholder" v-model="search" :disabled="inputKey">
                <div id="loader-user-search" class="lds-ring" v-if="loadingUser"><div></div><div></div><div></div><div></div></div>
                <fa v-if="inputKey" :icon="['fas', 'times']" @click="deleteNewPersonSelected" />
                <div id="dropdown-new" class="hidden">
                    <div v-for="user in users" @click="newPersonSelected(user.login, user.givenName, user.familyName)">
                        <p>{{user.givenName}} {{user.familyName}}</p>
                        <p>{{user.contactEmail}}</p>
                    </div>
                </div>
                <input hidden type="text" name="new-person-login" id="new-person-login" value="">
            </div>
            <button v-if="inputKey" id="btn-delete" class="btn" @click="newPerson(entity)">{{text.confirm}}</button>
        </div>
    </div>
    <confirm-dialogue ref="confirmDialogue" />
    <div id="wrapper-password-chge" :key="langKey" v-if="loading" >
        <ellipsis style="position: absolute; top: 50%;left: 50%;" />
    </div>
</template>

<script>
import ConfirmDialogue from '@/components/ConfirmDialogue.vue'
import Ellipsis from '@/components/Ellipsis.vue'

export default {
    components: { ConfirmDialogue },
    props:[
        'entity',
    ],
    data(){
        return{
            text:{
                "title": null,
                "search": null,
                "integrate": null,
                "placeholder": null,
                "confirm": null
            },
            commonTexts: {},
            langstore: 0,
            search: "",
            users:[],
            loadingUser: false,
            inputKey: false,
            isVisible: false,
            loading: false,
        };
    },
    mounted(){
        // requête pour textes selon langue
        this.getTexts();
    },
    computed: {
        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

        lang(){
            return this.$store.state.lang;
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        },

        // chaque fois que le champs recherche change (+ de 3 caractères), la requête pour trouver des users qui correspondes s'exécute.
        search(newSearch, oldSearch) {
            const dropNew = document.getElementById("dropdown-new");

            if (newSearch != oldSearch && newSearch.length > 2) {
              setTimeout(() => {
                //requête GET users pour récupére infos d'un utilisateur
                this.loadingUser = true;
                fetch(this.$baseUrl(`/api/users?filter=${newSearch}&limit=100`))
                .then(response => response.json())
                .then(data => {
                  this.users = data.data;
                  dropNew.classList.remove("hidden");
                  if(this.users.length > 0){
                    dropNew.classList.remove("hidden");
                    this.loadingUser = false;
                  }
                })
                .catch((error) => console.log(error));
              }, 500)
            }else if(newSearch.length <= 2){
              dropNew.classList.add("hidden");
              this.users = [];
            }
        }
    },
    methods: {
        open(){
            this.isVisible = true;
        },

        close(){
            this.search = "";
            this.users = [];
            this.loadingUser = false;
            this.inputKey = false;
            document.getElementById("dropdown-new").classList.add("hidden");
            this.loading = false;
            this.isVisible = false;
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.text = this.$I18n()[this.$store.state.lang].manageaccounts.new;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;
        },

        // Selection d'une nouvelle personne dans liste de recherche
        newPersonSelected(login, givenName, familyName){
            this.search = "" ;
            document.getElementById("search").placeholder = `${givenName} ${familyName}`;
            this.inputKey = true;
            document.getElementById("search-label").innerHTML = `${this.text.integrate} :`
            document.getElementById("new-person-login").value = login;
        },

        deleteNewPersonSelected(){
            document.getElementById("search").placeholder = this.text.placeholder;
            this.inputKey = false;
            document.getElementById("search-label").innerHTML = `${this.text.search} :`
            document.getElementById("new-person-login").value = "";
        },

        // POST de nouvelle personne dans unité selectionné
        newPerson(entity){
            let newPerson = document.getElementById("new-person-login").value;
            var body = JSON.stringify({uid: newPerson});

            this.loading = true;

            fetch(this.$baseUrl(`/api/users/${entity}/secondary`),
            {method: 'POST', body: body})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                }
                return response;
            })
            .then((response) => {
                this.deleteNewPersonSelected();
                // rafraichie le composant EditProfile pour réafficher les otherEmails correctement
                this.$store.commit('SETNEWPERSONKEY');
                this.close();
            })
            .catch((error) => {
                this.loading = false;

                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${error}`
                });
            });
        }

    },
};

</script>



<style lang="scss" scoped>

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";

</style>
