<template>
    <div id="wrapper-password-chge" :key="langKey" v-if="isVisible" >
        <div class="cont-password cont-password-chge">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{title}}</p>
                <fa :icon="['fas', 'times']" @click="close" />
            </div>
            <p v-if="lang == 'fr'" class="text-password">
                <ul>
                    <li>Pour intégrer une personne dans votre unité :
                        <ul>
                            <li>cliquez sur Intégrer une personne</li>
                            <li>recherchez son nom et validez</li>
                            <li>la personne sera intégrée en tant qu'invitée (pas de droits d'accès aux revues)</li>
                            <li>pour une présence temporaire dans l'unité, précisez la date de départ (et d'arrivée), elle sera retirée automatiquement</li>
                            <li>si elle doit devenir membre de l'unité, changez son statut</li>
                            <li>il faudra alors la validation de l'unité d'appartenance de cette personne si elle n'est pas extérieure</li>
                        </ul>
                    </li>
                    <li>Vous pouvez déplacer un membre de votre unité vers tout autre unité</li>
                    <li>Vous pouvez transformer un membre en personne extérieure, ou simplement la supprimer de votre unité</li>
                    <li>Une personne est dite extérieure si elle n'est membre d'aucune unité</li>
                    <li>Un compte ne se supprime pas, il suffit de le retirer de votre unité, le compte persiste avec des droits réduits</li>
                    <li>Les comptes extérieurs non revalidés depuis plus d'un an sont supprimés</li>
                    <li>Une personne ne peut être membre que d'une unité et invitée dans plusieurs</li>
                    <li>Les créations de comptes se font soit par les personnes elles-même si leur email d'établissementest correctement renseigné dans l'annuaire des Personnes (même avec un mailAternateAddress) soit par <a href="https://portail.math.cnrs.fr/service/Guest">l'onglet Invitations</a></li>
                </ul>
                <p>Pour tout problème : mail à <a href="mailto:support_@_math.cnrs.fr">support_@_math.cnrs.fr</a> </p>
            </p>
            <p v-else class="text-password">
                <ul>
                    <li>To integrate a person in your Entity :
                        <ul>
                            <li>click on Integrate a person</li>
                            <li>search for his name and validate</li>
                            <li>the person will be integrated as a guest (no access rights to journals)</li>
                            <li>for a temporary presence in the entity, specify the date of departure (and arrival), it will be automatically removed</li>
                            <li>if she has to become a member of the entity, change her status</li>
                            <li>you will need the validation of the entity to which this person belongs if he is not external</li>
                        </ul>
                    </li>
                    <li>You can move a member of your entity to any other entity</li>
                    <li>You can transform a member into an external person, or simply delete him from your entity</li>
                    <li>A person is said to be external if he is not a member of any entity</li>
                    <li>An account is not deleted, it's enough to remove it from your entity, the account persists with reduced rights</li>
                    <li>External accounts that have not been revalidated for more than a year are deleted</li>
                    <li>A person can only be a member of one entity and invited to several</li>
                    <li>The creation of accounts is done either by the persons themselves if their establishment email is correctly filled in the People directory (even with a mailAternateAddress) or by <a href="https://portail.math.cnrs.fr/service/Guest">the tab Invitations</a></li>
                </ul>
                <p>For any problem : mail to <a href="mailto:support_@_math.cnrs.fr">support_@_math.cnrs.fr</a> </p>
            </p>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{
            title: null,
            langstore: 0,
            isVisible: false,
        };
    },
    mounted(){
        // requête pour textes selon langue
        this.getTexts();
    },
    computed: {
        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

        lang(){
            return this.$store.state.lang;
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        }
    },
    methods: {
        open(){
            this.isVisible = true;
        },

        close(){
            this.isVisible = false;
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
          this.title = this.$I18n()[this.$store.state.lang].manageaccounts.help;
        }
    },
};

</script>



<style lang="scss" scoped>

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";

</style>
