
<template>

    <div id="wrapper-invitations">
        <div id="cont-title-invit">
            <p id="title-invit">{{texts.title}}</p>
            <div id="btn-help-invit" class="btn" @click="showHelpInvit"><fa :icon="['fas', 'question']"/></div>
        </div>
        <div id="cont-form">
            <form id="form-invitations" @submit.prevent="">
                <div>
                    <label for="name">{{texts.lastname}}</label>
                    <input type="text" class="namefield" name="name" id="name" v-model="form.lastname" pattern="^[a-zA-Z][a-zA-Z_ \-]{0,64}[a-z]$">
                </div>
                <div>
                    <label for="firstname">{{texts.firstname}}</label>
                    <input type="text" class="namefield" name="firstname" id="firstname" v-model="form.firstname" pattern="^[a-zA-Z][a-zA-Z_ \-]{0,64}[a-z]$">
                </div>
                <div>
                    <label for="mailVal">{{texts.mail}}</label>
                    <input type="email" name="mail" id="mail" placeholder="abcde@fghij.klm" v-model="form.mail">
                </div>
                <button id="btn-invit" :disabled="!formIsValid" @click="submitInvitation">{{texts.confirm}}</button>
            </form>
            <div>
                <h4>{{texts.titletext}}</h4>
                <p>{{texts.text1}}</p>
                <p>{{texts.text2}}</p>
                <p>{{texts.text3}}</p>
            </div>
        </div>

        <div class="wrapper-tab-invit">
            <div id="cont-titles-tabs">
                <div class="title-tab-accounts" id="title-tab-guests" @click="selectTabGuests">{{texts.titletabs[1]}}</div>
                <div class="title-tab-accounts unselected" id="title-tab-acc" @click="selectTabAccount">{{texts.titletabs[0]}}</div>
            </div>

            <!-- onglet Mes invités -->
            <div class="cont-table-invit" id="guests" :key="newUserKey" >
                <table >
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{texts.login}}</th>
                            <th>{{texts.lastname}}</th>
                            <th>{{texts.firstname}}</th>
                            <th>{{texts.mail}}</th>
                            <th>{{texts.date}}</th>
                        </tr>
                    </thead>
                    <tbody id="table-body" v-if="!loadingUsers">
                        <tr v-for="user in guests" :id="user.id">
                            <td v-bind:title='texts.delete' class="delete-cell">
                              <fa icon="times-circle" @click="deleteInvitation(user.id)"/>
                            </td>
                            <td v-if="user.login===''" v-bind:title='texts.redo'>
                              <div @click="renewInvitation(user.id)"><svg id="redo" version="1.1" baseProfile="full" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                              <path d="M496 48V192c0 17.69-14.31 32-32 32H320c-17.69 0-32-14.31-32-32s14.31-32 32-32h63.39c-29.97-39.7-77.25-63.78-127.6-63.78C167.7 96.22 96 167.9 96 256s71.69 159.8 159.8 159.8c34.88 0 68.03-11.03 95.88-31.94c14.22-10.53 34.22-7.75 44.81 6.375c10.59 14.16 7.75 34.22-6.375 44.81c-39.03 29.28-85.36 44.86-134.2 44.86C132.5 479.9 32 379.4 32 256s100.5-223.9 223.9-223.9c69.15 0 134 32.47 176.1 86.12V48c0-17.69 14.31-32 32-32S496 30.31 496 48z"/>
                              </svg></div>
                            </td>
                            <td v-else >{{user.login}}</td>
                            <td>{{user.familyName}}</td>
                            <td>{{user.givenName}}</td>
                            <td>{{user.contactEmail}}</td>
                            <td>{{user.ts}}</td>
                        </tr>
                    </tbody>
                </table>
                <ellipsis v-if="loadingUsers" />
            </div>

            <!-- onglet Comptes existants -->
            <div class="cont-table-invit display-none" id="exist-accounts" :key="newUserKey" >
                <table >
                    <thead>
                        <tr>
                            <th>{{texts.login}}</th>
                            <th>{{texts.lastname}}</th>
                            <th>{{texts.firstname}}</th>
                            <th>{{texts.mail}}</th>
                        </tr>
                    </thead>
                    <tbody id="table-body" v-if="!loadingUsers">
                        <tr v-for="user in users" :id="user.login">
                            <td>{{user.login}}</td>
                            <td>{{user.familyName}}</td>
                            <td>{{user.givenName}}</td>
                            <td>{{user.contactEmail}}</td>
                        </tr>
                    </tbody>
                </table>
                <ellipsis v-if="loadingUsers" />
            </div>
        </div>

    </div>

    <InvitHelp ref="invitHelp" />
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

</template>


<script>
import InvitHelp from '../components/InvitHelp.vue';
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import Ellipsis from '../components/Ellipsis.vue'
import debounce  from 'lodash/debounce';

export default {
    components: { InvitHelp, ConfirmDialogue, Ellipsis },
    data() {
        return {
            texts: {
                title: null,
                login: null,
                lastname: null,
                firstname: null,
                mail: null,
                date: null,
                confirm: null,
                delete: null,
                titletext: null,
                text1: null,
                text2: null,
                text3: null,
                titletabs: [],
                redo: null,
            },
            form: {
                firstname: "",
                lastname: "",
                mail: "",
            },
            commonTexts: {},
            langstore: 0,
            lastname: "",
            firstname: "",
            mail: "",
            loadingUsers: true,
            newUserKey: 0,
            users: [],
            guests: [],
            form_disabled: false,
        };
    },
    mounted() {
        // requête pour textes selon langue
        this.getTexts();
        //this.getInvitations();
    },
    computed: {
        // recharge composant à chaque changement de langue
        langKey() {
            this.langstore = this.$store.state.langKey;
        },

        formIsValid() {
          const regex = /^[a-z][a-z \-_\.@]{0,64}[a-z]$/i;
          const regex_mail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          return (regex_mail.test(this.form.mail) && regex.test(this.form.firstname) && regex.test(this.form.lastname));
        },
    },
    watch: {
        //  chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
                this.getTexts();
            }
        },
        form: {
            handler(newVal, oldVal) {
                this.selectTabAccount()
                this.filteredUsers(newVal)
            },
            deep: true
        }
    },
    methods: {
        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].invitations;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;
            this.getInvitations()
        },

        getInvitations() {
          // !! requête provisoir pour remplir les tableaux !!
          fetch(this.$baseUrl('/api/profile/invitations'))
          .then((response) => {
            if(response.ok) {
              return response.json();
            } else {
              if (response.status == 401) {
                this.showAnon(true);
              } else if (response.status == 403) {
                this.showGuest();
              } else {
                this.showAnon(false);
              }
            }
          })
          .then((data) => {
            this.guests = data;
            this.loadingUsers = false;
          })
          .catch((error) => {});
        },

        showHelpInvit(){
            this.$refs.invitHelp.open();
        },

        selectTabAccount(){
            let titleTabAcc = document.getElementById('title-tab-acc');
            let titleTabGue = document.getElementById('title-tab-guests');
            let tabAcc = document.getElementById('exist-accounts');
            let tabGue = document.getElementById('guests');

            if (titleTabAcc.classList.contains('unselected')) {
                titleTabAcc.classList.remove('unselected')
            }
            if (!titleTabGue.classList.contains('unselected')) {
                titleTabGue.classList.add('unselected')
            }

            if (tabAcc.classList.contains('display-none')) {
                tabAcc.classList.remove('display-none')
            }
            if (!tabGue.classList.contains('display-none')) {
                tabGue.classList.add('display-none')
            }
        },

        selectTabGuests(){
            let titleTabAcc = document.getElementById('title-tab-acc');
            let titleTabGue = document.getElementById('title-tab-guests');
            let tabAcc = document.getElementById('exist-accounts');
            let tabGue = document.getElementById('guests');

            if (!titleTabAcc.classList.contains('unselected')) {
                titleTabAcc.classList.add('unselected')
            }
            if (titleTabGue.classList.contains('unselected')) {
                titleTabGue.classList.remove('unselected')
            }

            if (!tabAcc.classList.contains('display-none')) {
                tabAcc.classList.add('display-none')
            }
            if (tabGue.classList.contains('display-none')) {
                tabGue.classList.remove('display-none')
            }
        },

        async showAnon(isAnon) {
          this.$refs.invitHelp.close();
          var title =  this.commonTexts.notplm.title;
          var message = this.commonTexts.notplm.message;
          var cancel = this.commonTexts.root;
          var back = "/";
          if (isAnon) {
            title =  this.commonTexts.anon.title;
            message = this.commonTexts.anon.message;
            cancel = this.commonTexts.anon.login;
            back = "/auth/plm";
          }
          const ok = await this.$refs.confirmDialogue.show({
            title: title,
            message: message,
            cancelButton: cancel
          })
          
          window.location = back;
        },

        async showGuest() {
          this.$refs.invitHelp.close();
          var title =  this.commonTexts.guestlimit.title;
          var message = this.commonTexts.guestlimit.message;
          var cancel = this.commonTexts.root;
          var back = "/";
          const ok = await this.$refs.confirmDialogue.show({
            title: title,
            message: message,
            cancelButton: cancel
          })

          window.location = back;
        },

        async deleteInvitation(id){
            const ok = await this.$refs.confirmDialogue.show({
                title: this.texts.deletemodal.title,
                message: this.texts.deletemodal.message,
                okButton: this.texts.deletemodal.ok,
                cancelButton: this.texts.deletemodal.cancel
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                fetch(this.$baseUrl('/api/profile/invitations/'+id),
                {method: 'DELETE'})
                .then(response => {
                    if (!response.ok) {
                       throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                    }
                    return response;
                })
                .then((response) => response.json())
                .then((data) => {
                   this.guests = data;
                   this.loadingUsers = false;
                })
                .catch((error) => {
                    this.$refs.confirmDialogue.show({
                        title: this.commonTexts.error,
                        message: `${this.commonTexts.genericError} \n ${error}`
                    });
                });
            }
        },

        async renewInvitation(id) {
            const ok = await this.$refs.confirmDialogue.show({
                title: this.texts.redomodal.title,
                message: this.texts.redomodal.message,
                okButton: this.texts.redomodal.ok,
                cancelButton: this.texts.redomodal.cancel
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                fetch(this.$baseUrl('/api/profile/invitations/'+id),
                {method: 'PATCH'})
                .then(response => {
                    if (!response.ok) {
                       throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                    }
                    return response;
                })
                .then((response) => {
                    this.$refs.confirmDialogue.show({
                        title: this.commonTexts.generic,
                        message: this.commonTexts.genericAnswer,
                        okButton: this.commonTexts.ok
                    });
                })
                .catch((error) => {
                    this.$refs.confirmDialogue.show({
                        title: this.commonTexts.error,
                        message: `${this.commonTexts.genericError} \n ${error}`
                    });
                });
            }
        },

        submitInvitation() {
            this.selectTabGuests();
            fetch(this.$baseUrl('/api/profile/invitations'),
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    givenName: this.form.firstname,
                    familyName: this.form.lastname,
                    contactEmail: this.form.mail
                    })
            })
            .then(response => {
                if (!response.ok) {
                   throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                }
                return response;
            })
            .then((response) => response.json())
            .then((data) => {
               this.guests = data;
               this.loadingUsers = false;
               this.$refs.confirmDialogue.show({
                    title: this.commonTexts.generic,
                    message: this.commonTexts.genericAnswer
                });
                this.form.firstname = '', this.form.lastname = '', this.form.mail = ''
            })
            .catch((error) => {
                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${error}`
                });
            });
        },

        filteredUsers: debounce(function(data) {

            var firstname = data['firstname'].trim().toLowerCase()
            var lastname = data['lastname'].trim().toLowerCase()
            var mail = data['mail'].trim().toLowerCase()


            var filterFirst = 'firstname=';
            var filterLast = 'lastname=';
            var filterMail = 'contactEmail=';

            let validFirst = false;
            let validLast = false;
            let validMail = false;

            let regex = /^[a-zA-Z][a-zA-Z _-]{0,64}[a-zA-Z]$/
            let regex_mail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

            if (regex.test(firstname)) {
                filterFirst+=firstname;
                validFirst = true
            }

            if (regex.test(lastname)) {
                filterLast+=lastname;
                validLast = true
            }
            if (regex_mail.test(mail)) {
                filterMail+=mail;
                validMail = true
            }

            var filter = filterFirst+'&'+filterLast+'&'+filterMail;

            if (!this.form_disabled && (validFirst || validLast || validMail)) {
                this.form_disabled = true;
                fetch(this.$baseUrl('/api/users/search?'+filter))
                .then((response) => response.json())
                .then((data) => {
                    this.users = data;
                    this.loadingUsers = false;
                    this.form_disabled = false;
                })
                .catch((error) => {
                    this.users = [];
                    console.log(error);
                    this.form_disabled = false;
                });
            } else this.selectTabGuests()
        },300 )
    },
};

</script>


<style lang="scss" scoped>

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/invitations.scss";

</style>
