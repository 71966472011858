<template>


    <div :key="refreshVPNUser">
        <div id="wrapper-password-chge" :key="langKey" v-if="isVisible" >
            <div class="cont-password cont-password-chge">
                <div class="cont-title-password">
                    <p class="title-password" id="titlePassword">{{texts.title}}</p>
                    <fa :icon="['fas', 'times']" @click="close" />
                </div>
                <div class="cont-xl">
                    <div class="cont-vpns" v-for="vpn in vpns">
                        <input type="text" class="newip" :value="vpn.ip" disabled>
                        <input type="text" class="newdescri" :value="vpn.description" disabled>
                        <button class="btn-minus" @click="removeIP(vpn.ip)"><fa :icon="['fas', 'times-circle']"/></button>
                        <button class="btn-edit" @click="chgeVPN(vpn.ip, vpn.description)"><fa :icon="['fas', 'edit']"/></button>

                    </div>
                    <div id="cont-new-vpn">
                        <input type="text" class="newip" v-model="newIP" placeholder="255.255.255.255" :class="{ inputerrorvpn : !newIPIsValid }">
                        <input type="text" class="newdescri" v-model="newDescri" placeholder="abcdef" :class="{ inputerrorvpn : !newDescriIsValid }">
                        <button class="btn-plus" :disabled="!formIsValid || (newIP=='' && newDescri=='')" @click="addIP"><fa :icon="['fas', 'plus-circle']"/></button>
                    </div>
                </div>
                <button id="btn-save-vpn-entity" class="btn" :disabled="!chgeKey" @click="saveVPN">{{texts.save}}</button>
            </div>
        </div>

        <div id="wrapper-chge-vpn-user" class="hidden" :key="langKey">
            <div id="cont-chge-vpn">
                <div class="cont-title-password">
                    <p class="title-password" id="titlePassword">{{texts.titlechge}}</p>
                    <fa :icon="['fas', 'times']" @click="closeVPNchge" />
                </div>
                <div id="cont-new-vpn">
                    <input type="text" class="newip" v-model="chgeIPUser" :class="{ inputerrorvpn : !chgeIPIsValid }">
                    <input type="text" class="newdescri" v-model="chgeDescriUser" :class="{ inputerrorvpn : !chgeDescriIsValid }">
                    <button class="btn-plus" :disabled="!formChgeIsValid" @click="validChgeVPN"><fa :icon="['fas', 'check-circle']"/></button>
                </div>
            </div>
        </div>
    </div>
    <confirm-dialogue ref="confirmDialogue" />

</template>

<script>
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';

export default {
    components: {
        ConfirmDialogue,
    },    
    props:[
        'user',
    ],
    data(){
        return{
            texts:{
                title: null,
                titlechge: null,
                save: null,
            },
            commonTexts: {},
            langstore: 0,
            userSelectedKey: false,
            chgeKey: 0,
            vpns: [],
            newIP: "",
            newDescri: "",
            chgeIPUser: "",
            chgeOldIPUser: "",
            chgeDescriUser: "",
            isVisible: false,

        };
    },
    mounted(){

        // requête pour textes selon langue
        this.getTexts();

        this.getVPNsUser();

    },
    computed: {
        // recharge composant à chaque reouverture
        refreshVPNUser(){
            this.userSelectedKey = this.$store.state.showVPNUserStore;
        },

        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

        // "!!" convertie en boolean pour tester si champ est rempli
        newIPIsValid(){
            return ( !(!!this.newIP) || /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.newIP))
        },

        newDescriIsValid(){
            if(( !(!!this.newIP) && !(!!this.newDescri)) || (this.newIPIsValid && (!!this.newDescri))){
                return true
            }else{
                return false
            }
        },

        formIsValid(){
            return this.newIPIsValid && this.newDescriIsValid
        },

        chgeIPIsValid(){
            return ( !(!!this.chgeIPUser) || /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.chgeIPUser))
        },

        chgeDescriIsValid(){
            if(( !(!!this.chgeIPUser) && !(!!this.chgeDescriUser)) || (this.chgeIPIsValid && (!!this.chgeDescriUser))){
                return true
            }else{
                return false
            }
        },

        formChgeIsValid(){
            return this.chgeIPIsValid && this.chgeDescriIsValid
        },

    },
    watch: {
        //  chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        },

        //  chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        userSelectedKey(newEntitySelectedKey, oldEntitySelectedKey) {
            if (newEntitySelectedKey > oldEntitySelectedKey) {
            this.getVPNsUser()
            }
        },
    },
    methods: {

        getVPNsUser(){
            this.vpns = this.user.vpn;
        },

        open(){
            this.isVisible = true;
            this.getVPNsUser();
        },

        close(){
            this.chgeKey = 0;
            this.newIP = "";
            this.newDescri = "";
            this.vpns = [];
            this.isVisible = false;
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
          this.texts = this.$I18n()[this.$store.state.lang].savevpnuser;
          this.commonTexts = this.$I18n()[this.$store.state.lang].common;
        },

        addIP(){
            this.chgeKey += 1;
            let newIPdata = {};
            newIPdata.ip = this.newIP;
            newIPdata.description = this.newDescri;
            newIPdata.fqdn = "";
            this.vpns.push(newIPdata);
            this.newIP = "";
            this.newDescri = "";
        },

        removeIP(ip){
            this.chgeKey += 1;
            this.vpns.splice(this.vpns.indexOf(this.vpns.find(vpn => vpn.ip === ip)), 1);
        },

        chgeVPN(ip, descri){
            document.getElementById("wrapper-chge-vpn-user").classList.remove("hidden")
            this.chgeIPUser = ip;
            this.chgeOldIPUser = ip;
            this.chgeDescriUser = descri;
        },

        closeVPNchge(){
            document.getElementById("wrapper-chge-vpn-user").classList.add("hidden")
        },

        validChgeVPN(){
            this.chgeKey += 1;
            // j'efface l'ancien VPN
            this.vpns.splice(this.vpns.indexOf(this.vpns.find(vpn => vpn.ip === this.chgeOldIPUser)), 1);
            // et je j'ajoute le nouveau (modification de l'ancien)
            let newVPNdata = {};
            newVPNdata.ip = this.chgeIPUser;
            newVPNdata.description = this.chgeDescriUser;
            newVPNdata.fqdn = "";
            this.vpns.push(newVPNdata);
            this.chgeIPUser = "";
            this.chgeDescriUser = "";

            document.getElementById("wrapper-chge-vpn-user").classList.add("hidden");
        },

        saveVPN(){
            var body = JSON.stringify({vpns: this.vpns});
            fetch(this.$baseUrl(`/api/users/${this.user.login}/vpn`),
            {method: 'PATCH', body: body})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                }
                return response;
            })
            .then(response => {
                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.generic,
                    message: this.commonTexts.genericAnswer
                });
                this.chgeKey = 0;
                this.newIP = "";
                this.newDescri = "";
                this.vpns = [];
                this.close();
            })
            .catch((error) => {
                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${error}`
                });
            });
        },
    },
};

</script>



<style lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/vpn.scss";

</style>
