<template>


    <div id="wrapper-password-chge" :key="langKey" v-if="isVisible" >
        <div class="cont-password cont-password-chge">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{texts.title}}</p>
                <fa :icon="['fas', 'times']" @click="close" />
            </div>
            <p class="text-password">{{texts.banner}} <b>{{user.givenName}} {{user.familyName}}</b> {{texts.text2}}</p>
            <p class="text-password"><span v-html="comment"></span></p>
            <div id="btn-delete" class="btn" @click="chgeStatusAccount">{{texts.ok}}</div>
        </div>
    </div>

    <confirm-dialogue ref="confirmDialogue" />
    <div id="wrapper-password-chge" :key="langKey" v-if="loading" >
        <ellipsis style="position: absolute; top: 50%;left: 50%;" />
    </div>
</template>

<script>
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import Ellipsis from '../components/Ellipsis.vue'

export default {
    components: { ConfirmDialogue, Ellipsis },
    props:[
        'entity',
        'user',
        'member'
    ],
    data(){
        return{
            texts:{
                title: '',
                banner: '',
                ok: '',
            },
            comment: '',
            commonTexts: {},
            langstore: 0,
            isVisible: false,
            loading: false,
        };
    },
    mounted(){

        // requête pour textes selon langue
        this.getTexts();

    },
    computed: {
        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

    },
    watch: {
        //  chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        },
    },
    methods: {
        open(){
            var cat = 'guest';
            if (this.member) {
                cat = 'member';
            }
            console.log(cat);
            this.comment = this.texts[cat];
            this.isVisible = true;
        },
        
        close(){
            this.loading =  false;
            this.isVisible = false;
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].chgestatusaccount;
            this.comment = this.texts.guest;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;
        },

        chgeStatusAccount(){
          var body = JSON.stringify({uid: this.user.login});

          this.loading = true;

          if (this.user.primaryAffectation.id == this.entity) {
            fetch(this.$baseUrl(`/api/users/${this.entity}/secondary`) ,
            {method: 'PATCH', body: body})
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                }
                return response;
            })
            .then((response) => {
              this.close();
              this.$store.commit('SETNEWPERSONKEY')
            })
            .catch((error) => {
                this.loading =  false;
               
                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${error}`
                });
            });
          } else {
            fetch(this.$baseUrl(`/api/users/${this.entity}/primary`) ,
            {method: 'PUT', body: body})
            .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
            }
                return response;
            })
            .then((response) => {
                this.close();
                this.$store.commit('SETNEWPERSONKEY')
            })
            .catch((error) => {
                this.loading =  false;

                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${error}`
                });
            });
          }
        },

    },
};

</script>



<style lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";



</style>
