<template >

    <div :key="langKey" v-if="!profile.anonymous">
        <div id="cont-profile">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{texts.title}}</p>
                <router-link to="/"><fa :icon="['fas', 'times']" /></router-link>
            </div>
            <div class="text-password row">
                <p><b>{{texts.textIDP}}</b></p>
                <div id="div-row1">
                    <p>{{profile.idp}}</p>
                </div>
            </div>
            <div class="text-password row">
                <p><b>{{texts.textL1}}</b></p>
                <div id="div-row1">
                    <p>{{displayName}}</p>
                </div>
            </div>
            <p class="text-password row" v-if="profile.labs" ><b>{{texts.textL2}}</b>
                <div>
                    <span v-for="lab in profile.labs">
                        <span v-if="lab.primary">{{lab.desc}}
                        </span>
                    </span>
                </div>
            </p>
            <div class="text-password row" id="cont-contact-mail" v-if="profile.login">
                <label for="contact-mail" @click="showModaleHelp()">{{texts.textL3}}<fa icon="question-circle"/></label>
                <select name="contact-mail" id="contact-mail" @change="showModaleChgeContactMail($event)">
                    <option :value="profile.contactEmail">{{profile.contactEmail}}</option>
                    <!-- <option v-if="profile.plmEmail" :value="profile.plmEmail">{{profile.plmEmail}}</option> -->
                    <option v-for="other in profile.otherEmails" :value="other">{{other}}</option>
                </select>
            </div>
            <div class="text-password row" id="cont-contact-mail" v-if="!profile.login">
                <label for="contact-mail">{{texts.textL3}}</label>
                <p>{{profile.contactEmail}}</p>
            </div>

            <div id="cont-mails" v-if="profile.otherEmails">
                <p id="title-mails">{{texts.emails}}</p>
                <ul>
                    <li v-for=" (other, index) in profile.otherEmails" :id="index"><div v-if="other !== profile.contactEmail">{{other}}<fa v-if="profile.login" icon="times-circle" @click="showModalDeleteEmail(other)"/></div></li>
                </ul>
            </div>

            <div id="cont-plm" v-if="profile.login">
                <p id="title-plm">{{texts.plmId}}</p>
                <p class="row text-password" id="login-plm-edit"><b>{{texts.textL4}}</b><div> {{profile.login}}</div></p>
                <div id="cta-chge-password" @click="showModalPassChge">{{texts.chgePass}}</div>
                <p class="row text-password" id="login-plm-edit" v-if="profile.plmEmail"><b>{{texts.textL5}}</b><div> {{profile.plmEmail}}</div></p>
                <form class="form-password" action="" method="post">
                    <div id="cont-shell">
                        <label for="shell">Shell:</label>
                        <select name="shell" id="shell" @change="showModaleChgeShell($event)">
                            <option :value="profile.shell">{{profile.shell}}</option>
                            <option v-for="shell in shells" :value="shell.loginShell" >{{shell.loginShell}}</option>
                        </select>
                    </div>
                </form>
            </div>

            <div id="cont-plm" v-if="!profile.login && !profile.anonymous">
                <p id="title-plm">{{texts.associateTitle}}</p>
                <p class="row text-password" v-if="profile.insmi" v-html="texts.associateTextINSMI"></p>
                <p class="row text-password" v-else v-html="texts.associateText"></p>
                <div id="cta-chge-password" @click="associate">{{texts.associate}}</div>
                <div id="cta-chge-password" v-if="profile.insmi && !profile.converged_with_plm" @click="showNewAccount">{{texts.create}}</div>
            </div>
        </div>

    </div>

    <HelpMail ref="helpMail" />
    <ChangePassword ref="modalPassword" />
    <PasswordValidation ref="newAccount" />
    <ChangeProfile ref="changeProfile" :key="langKey" @reload="refreshProfile" />
    <Associate ref="associate" @reload="refreshProfile" />

</template>

<script>

import ChangePassword from '@/components/ChangePassword.vue';
import HelpMail from '@/components/HelpMail.vue';
import ChangeProfile from '@/components/ChangeProfile.vue';
import Associate from '@/components/Associate.vue';


export default {
    components: {
        ChangePassword,
        HelpMail, 
        ChangeProfile,
        Associate,
    },
    data(){
        return{
            profile: {},
            displayName: '',
            shells: [],
            texts:{
                title: null,
                textL1: null,
                textL2: null,
                textL3: null,
                textL4: null,
                textL5: null,
                emails: null,
                plmId: null,
                chgePass: null,
                associate: null,
                associateText: null,
            },
            langstore: null,
            category: 'unset',
            value: 'unset'
        };
    },
    created() {
        this.refreshProfile();

    },
    computed:{
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        },
    },
    methods: {

        refreshProfile() {
            fetch(this.$baseUrl('/api/profile'))
            .then(response => response.json())
            .then(data => {
              this.profile = data;
              this.displayName = data.names.displayName;
            })
            .catch((error) => console.log(error));

            //requête GET shells
            fetch(this.$baseUrl("/api/profile/shells"))
            .then(response => response.json())
            .then(data => {
               this.shells = data;
            })
            .catch((error) => console.log(error));

            // requête pour textes selon langue

            this.texts = this.$I18n()[this.$store.state.lang].editprofil;
        },

        // affichage modale changement password
        showModalPassChge() {
            this.$refs.modalPassword.open();
        },

        // affichage modale aide mail contact
        showModaleHelp() {
            if (this.profile.login && this.profile.otherEmails) {
                this.$refs.helpMail.open();
            }
        },

        // affichage modale suppression mail
        showModalDeleteEmail(mail) {
            this.$refs.changeProfile.setCategory('deleteEmail');
            this.$refs.changeProfile.setValue(mail);
            this.$refs.changeProfile.open();
        },

        // affichage modale Changement mail de contact
        showModaleChgeContactMail(newContactMail) {
            this.$refs.changeProfile.setCategory('changeContactEmail');
            this.$refs.changeProfile.setValue(newContactMail.target.value);``
            this.$refs.changeProfile.open();
        },

        // affichage modale Changement Shell
        showModaleChgeShell(newShell) {
            this.$refs.changeProfile.setCategory('changeShell');
            this.$refs.changeProfile.setValue(newShell.target.value);``
            this.$refs.changeProfile.open();
        },

        associate() {
            this.$refs.associate.open();
        },

        showNewAccount() {
            this.$router.push("/password");
        },

        //refait la requête pour les textes au changement de langue, les otherMails et shell à la modification de ceux-ci
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].editprofil;
            fetch(this.$baseUrl('/api/profile'))
            .then(response => response.json())
            .then(data => {
                this.profile = data;
            })
            .catch((error) => console.log(error));
        },

    },
};

</script>

<style lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/profile.scss";

</style>
