<template>
    <div id="wrapper-password-chge" :key="langKey" v-if="isVisible">
        <div class="cont-password cont-password-chge">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{texts.title}}</p>
                <fa :icon="['fas', 'times']" @click="close" />
            </div>
            <p class="text-password" id="t-pass">{{texts.text}}</p>
            <form class="form-password" @submit.prevent="submitAssociate">
                <div>
                    <label >{{texts.login}}</label>
                    <input pattern="^[a-zA-Z0-9][a-zA-Z0-9_\.@ -]*[a-z0-9]$" v-model="form.login" id="login" :class="{ inputerror : !loginIsValid }">
                </div>
                <div>
                    <label for="password">{{texts.password}}</label>
                    <div>
                        <fa id="pass-eye" class="eyes" :icon="['fas', 'eye']" @click="showPass"/>
                        <fa id="pass-eye-slash" class="eyes display-none" :icon="['fas', 'eye-slash']" @click="dontShowPass"/>
                        <input type="password" name="password" id="password" v-bind:placeholder="texts.password" v-model="password" :class="{ inputerror : !passwordIsValid }">
                    </div>
                </div>
                <button v-if="!profile.login" :disabled="!formIsValid" id="submit-pass-chge">{{texts.submit}}</button>
            </form>
        </div>
    </div>
    <div id="wrapper-password-chge" :key="langKey" v-if="loading" >
        <ellipsis  style="position: absolute; top: 50%;left: 50%;" />
    </div>
    <confirm-dialogue ref="confirmDialogue" />

</template>

<script>
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import Ellipsis from '../components/Ellipsis.vue'

export default {
    components: { ConfirmDialogue, Ellipsis },
    emits: ["reload"],
    data(){
        return{
            isVisible: false,
            profile: [],
            texts: {
                title: null,
                textS: null,
                textL1: null,
                textL2: null,
                textL3: null,
                login: null,
                mail: null,
                submit: null,
                logmsg: null,
                mailmsg: null
            },
            commonTexts: {},
            langstore: 0,
            password: '',
            form: {
                login: null,
                password: null,
            },
            loading: false,
        };
    },
    mounted(){
        // requête pour textes selon langue
        this.getTexts();

    },
    computed: {
        // au changement de la varible de l'etat de connexion (ds le store) certains boutons apparaissent
        connectState(){
            return this.$store.state.connectState;
        },

        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

        // "!!" convertie en boolean pour tester si champ est rempli
        loginIsValid(){
            return !!this.form.login && (/^[a-zA-Z0-9][a-zA-Z0-9_\.@ -]*[a-z0-9]$/.test(this.form.login))
        },

        passwordIsValid(){
            return !!this.password && (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{8,}$/.test(this.password))
        },

        formIsValid(){
            console.log(this.loginIsValid,this.passwordIsValid)
            return this.loginIsValid && this.passwordIsValid
        },

    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        }
    },
    methods: {
        open(){
            this.isVisible = true;
            // requête info de profile
            //requête GET profile
            fetch(this.$baseUrl("/api/profile"))
            .then(response => response.json())
            .then(data => {
                this.profile = data;
            })
            .catch((error) => console.log(error));
        },

        close(){
            this.form.login = "";
            this.form.password = "";
            this.loading =  false;
            this.isVisible = false;
        },

        showPass(){
            document.getElementById("password").setAttribute("type","text");
            document.getElementById("pass-eye").classList.add("display-none");
            document.getElementById("pass-eye-slash").classList.remove("display-none");
        },

        dontShowPass(){
            document.getElementById("password").setAttribute("type","password");
            document.getElementById("pass-eye-slash").classList.add("display-none");
            document.getElementById("pass-eye").classList.remove("display-none");
        },

        submitAssociate(){
            var body = JSON.stringify({associate: true});
            let auth = btoa(`${this.form.login}:${this.password}`);
            this.loading =  true;

            fetch(this.$baseUrl("/api/password"),
            {method: 'PUT', body: body,
            headers: {'Authorization': `Basic ${auth}`}})
            .then((response) => {
            //this.loading =  false;
            if (!response.ok) {
                    const error = new Error ('Request Error',{cause: response});
                    throw error
                }
                return response;
            })
            .then(response => {
                this.close();
                this.$emit('reload', true);

                this.$refs.confirmDialogue.show({
                title: this.commonTexts.generic,
                message: this.texts.genericAnswer
                })
            })
            .catch((error) => {
                //this.close();
                error.cause.text().then((text)=> {
                    this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${text}`
                    })
                })
            })

        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].associate;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;
        }
    },


};

</script>



<style lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";

</style>
