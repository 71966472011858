<template>


    <div id="wrapper-password-chge" :key="langKey" v-if="isVisible" >
        <div class="cont-password cont-password-chge">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{texts.title}}</p>
                <fa :icon="['fas', 'times']" @click="close" />
            </div>
            <p class="text-password text-move-account">{{texts.text}}</p>
            <p class="text-delete-mail">{{user.givenName}} {{user.familyName}}</p>
            <p class="text-password text-move-account">{{texts.text2}}</p>
            <select name="move-unit-choice" id="move-unit-choice" v-model="unitSelected">
                <option disabled value="">{{texts.placeholder}}</option>
                <option v-for="entity in entities" :value="entity.id">{{entity.name}}</option>
            </select>
            <button id="btn-move" class="btn" :disabled="unitSelected == ''" @click="moveAccount">{{texts.move}}</button>
        </div>
    </div>
    <confirm-dialogue ref="confirmDialogue" />
    <div id="wrapper-password-chge" :key="langKey" v-if="loading" >
        <ellipsis style="position: absolute; top: 50%;left: 50%;" />
    </div>

</template>

<script>
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import Ellipsis from '../components/Ellipsis.vue'

export default {
    components: { ConfirmDialogue, Ellipsis },
    props:[
        'user',
        'entities'
    ],
    data(){
        return{
            texts:{
                title: null,
                placeholder: null,
                text: null,
                text2: null,
                move: null,
            },
            commonTexts: {},
            langstore: 0,
            unitSelected: "",
            isVisible: false,
            loading: false,
        };
    },
    mounted(){

        // requête pour textes selon langue
        this.getTexts();

    },
    computed: {
        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

    },
    watch: {
        //  chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        },
    },
    methods: {
        open(){
            this.isVisible = true;
        },

        close(){
            this.loading = false;
            this.unitSelected = "";
            this.isVisible = false;
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].moveaccount;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;

        },

        moveAccount(){
            var body = JSON.stringify({uid: this.user.login});

            this.loading = true;

            fetch(this.$baseUrl(`/api/users/${this.unitSelected}/primary`),
            {method: 'POST', body: body})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                }
                return response;
            })
            .then(data => {
                this.close();
                this.$store.commit('SETNEWPERSONKEY')
            })
            .catch((error) => {
                this.loading = false;

                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${error}`
                });
            });
        },
    },
};

</script>



<style lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";



</style>
