<template>
    <div id="wrapper-password-chge" :key="langKey" v-if="isVisible" >
        <div class="cont-password cont-password-chge">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{texts.title}}</p>
                <fa :icon="['fas', 'times']" @click="close" />
            </div>
            <p class="text-password">{{texts.text}}</p>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{
            texts:{
                title: null,
                text: null,
            },
            langstore: 0,
            isVisible: false,
        };
    },
    mounted(){
        // requête pour textes selon langue
        this.getTexts();
    },
    computed: {

        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        }
    },
    methods: {
        open(){
            this.isVisible = true;
        },

        close(){
            this.isVisible = false;
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
          this.texts = this.$I18n()[this.$store.state.lang].helpmail;
          }
    },
};

</script>



<style lang="scss" scoped>

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";

</style>
