<template>

    <div  :key="langKey" v-if="isVisible">
        <div id="wrapper-password-chge" :key="langKey">
            <div class="cont-password cont-password-chge">
                <div class="cont-title-password">
                    <p class="title-password" id="titlePassword">{{texts.title}}</p>
                    <fa :icon="['fas', 'times']" @click="close" />
                </div>
                <p class="text-password"><b>{{texts.text}}</b></p>
                <p class="text-delete-mail">{{value}}</p>
                <div id="btn-delete" class="btn" @click="changeValue()">{{texts.change}}</div>
            </div>
        </div>
    </div>
    <confirm-dialogue ref="confirmDialogue" />
</template>

<script>
import ConfirmDialogue from '../components/ConfirmDialogue.vue'

export default {
    components: { ConfirmDialogue },
    emits: ["reload"],
    data(){
        return{
            category: 'unset',
            value: 'unset',
            texts:{
                title: null,
                text: null,
                change: null,
            },
            isVisible: false,
            commonTexts: {},
            langstore: null,
        };
    },
    mounted(){
        // requête pour textes selon langue
        this.getTexts();

    },
    computed: {
        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
                this.getTexts()
            }
        },

    },
    methods: {
        open(){
            this.getTexts();
            this.isVisible = true;
        },

        close(){
            this.isVisible = false;
        },

        setCategory(cat) {
            this.category = cat;
        },

        setValue(val) {
            this.value = val;
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            if (this.category != 'unset') {
                this.texts = this.$I18n()[this.$store.state.lang][this.category];
                this.commonTexts = this.$I18n()[this.$store.state.lang].common;
            }
        },

        // modifie la categorie en envoyant leurs valeures dans le body de la requête
        changeValue(){
            var body = JSON.stringify({type: this.category, value: this.value});
            fetch(this.$baseUrl("/api/profile"),
            {method: 'PATCH', body: body})
            .then(response => {
            if (!response.ok) {
                const error = new Error ('Request Error',{cause: response});
                throw error
            }
                return response;
            })
            .then(response => {
                this.close(),
                this.$refs.confirmDialogue.show({
                    title: this.commonTexts.generic,
                    message: this.commonTexts.genericAnswer
                });
                this.$emit('reload', true);
                // rafraichie le composant EditProfile pour réafficher les otherEmails correctement
                this.$store.commit('SETLANGKEY');
            })
            .catch((error) => {
                    error.cause.text().then((text)=> {
                        this.$refs.confirmDialogue.show({
                        title: this.commonTexts.error,
                        message: `${this.commonTexts.genericError} \n ${text}`
                    });
                })
            });
            this.close();
        },
    },
};

</script>



<style lang="scss" scoped >

// @import "../style/style.module.scss";
// @import "../style/typo.scss";


</style>
