<template>

    <div id="wrapper-invit-help" :key="langKey" v-if="isVisible" >
        <div id="cont-invit-help">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{texts.title}}</p>
                <fa :icon="['fas', 'times']" @click="close" />
            </div>
            <div id="cont-texts-invit">
                <p v-html="help_text"></p>
            </div>
        </div>
    </div>


</template>

<script>

export default {
    data(){
        return{
            texts:{
                title: '',
                help: [],
                help_text: '',
            },
            langstore: 0,
            isVisible: true,
        };
    },
    mounted(){

        // requête pour textes selon langue
        this.getTexts();

    },
    computed: {
        showInvitHelp(){
            return this.$store.state.showInvitHelpStore;
        },

        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },
    },
    watch: {
        //  chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        },
    },
    methods: {
        open(){
            this.isVisible = true;
        },
        close(){
            this.isVisible = false;
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].invithelp;
            this.help_text = this.texts.help.join('');
        },

    },
};

</script>



<style lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";

</style>
